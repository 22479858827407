import * as L from "lodash";

declare global {
  interface Array<T> {
    returningAppended(elem: T): T[]
    returningAppendedN(elems: T[]): T[]
    returningShuffled(): T[]
    returningRandomElement(): T
    returningRandomElements(n: number): T[]
    returningSorted(): T[]
    returningSortedWith(f: (l: T, r: T) => number)
    returningUniq(): T[]
    returningFiltered(pred: (T) => boolean): T[]
  }
}

Array.prototype.returningShuffled = function () {
  return L.shuffle(this)
}

Array.prototype.returningAppended = function (x) {
  let dup = [...this]
  dup.push(x)
  return dup
}

Array.prototype.returningAppendedN = function (xs) {
  let dup = [...this]
  xs.forEach(x => dup.push(x))
  return dup
}

Array.prototype.returningSorted = function () {
  let dup = [...this]
  return dup.sort()
}

Array.prototype.returningSortedWith = function (f: (l, r) => number) {
  let dup = [...this]
  return dup.sort((l, r) => f(l, r))
}

Array.prototype.returningUniq = function () {
  return L.uniq(this)
}

Array.prototype.returningFiltered = function (pred: (T) => boolean) {
  let acc = []

  for (let e of this) {
    if (pred(e)) {
      acc.push(e)
    }
  }

  return acc
}

Array.prototype.returningRandomElement = function () {
  return this.returningShuffled()[0]
}

Array.prototype.returningRandomElements = function (n: number) {
  return this.returningShuffled().slice(0, n)
}

