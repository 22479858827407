import { Verb, removeMacrons } from '../verb';
import { AppContext } from "../app_context";

import * as React from 'react';
import * as L from 'lodash';

export { Dictionary };

interface DictionaryEntryProps {
  verb: Verb
}

type WordProps = {
  word: string
}

const RemoveMacrons: boolean = true

const Word: React.FunctionComponent<WordProps> = ({ word }) => {

  return <AppContext.Consumer>
    { ctx => {
        if (ctx.renderMacrons) {
          return <div>{word}</div>
        } else {
          return <div>{ removeMacrons(word) }</div>
        }
    }}
  </AppContext.Consumer>
}

class DictionaryEntry extends React.Component<DictionaryEntryProps> {
  constructor(props: DictionaryEntryProps) {
    super(props)
  }

  render(): React.ReactNode {
    return <li className='card'>
      <h3 className='present_infinitive'>
        <Word word={ this.props.verb.present_infinitive }></Word>
      </h3>

      <table>
        <tbody>
        <tr>
          <td className='present_p1_s'><Word word={ this.props.verb.present_p1_s }></Word></td>
          <td className='present_p2_s'><Word word={ this.props.verb.present_p2_s }></Word></td>
          <td className='perfect_p1_s'><Word word={ this.props.verb.perfect_p1_s }></Word></td>
          <td className='ppp_n'><Word word={ this.props.verb.pppNeuter() }></Word></td>
          <td className='german' rowSpan={2}>
            {this.props.verb.german.join(', ') }
          </td>
        </tr>
        <tr>
          <th className='present_p1_s'>Präsens, 1. P. Sg.</th>
          <th className='present_p2_s'>Präsens, 2. P. Sg.</th>
          <th className='perfect_p1_s'>Perfekt, 1. P. Sg.</th>
          <th className='ppp_n'>PPP, neutral</th>
          <th className='german'/>
        </tr>
        </tbody>
      </table>

      <h4>Indikativ Präsens</h4>

      {/*<table>
        <tbody>
          <tr>
            <th> </th>
            <th>Singular</th>
            <th>Plural</th>
          </tr>
          <tr>
            <th>1</th>
            <td><Word word={ this.props.verb.present_p1_s } /></td>
            <td><Word word={ this.props.verb.present_p1_p } /></td>
          </tr>
          <tr>
            <th>2</th>
            <td><Word word={ this.props.verb.present_p2_s } /></td>
            <td><Word word={ this.props.verb.present_p2_p } /></td>
          </tr>
          <tr>
            <th>3</th>
            <td><Word word={ this.props.verb.present_p3_s } /></td>
            <td><Word word={ this.props.verb.present_p3_p } /></td>
          </tr>
        </tbody>
      </table>*/}

    </li>
  }
}

interface DictionaryState {
  searchWord: string
}

interface DictionaryProps {
  verbs: Verb[],
}

class Dictionary extends React.Component<DictionaryProps, DictionaryState> {
  constructor(props: DictionaryProps) {
    super(props)

    let lastSearchWord: string = null
    let persistedSearchWord = window.localStorage.getItem('lastSearchWord')

    if (persistedSearchWord) {
      lastSearchWord = JSON.parse(persistedSearchWord);
    }

    this.state = { searchWord: lastSearchWord }
    this.handleChange = this.handleChange.bind(this)
  }

  sortedVerbs(): Verb[] {
    let sorted: Verb[] = L.filter(L.sortBy(this.props.verbs, (v) => v.present_infinitive));

    if (this.state.searchWord && this.state.searchWord != '') {
      return L.filter(sorted, w => w.matchesAny(this.state.searchWord))
    } else {
      return sorted;
    }
  }

  handleChange(event: React.FormEvent<HTMLInputElement>) {
    let entry = event.currentTarget.value;

    window.setTimeout(() => {
      window.localStorage.setItem('lastSearchWord', JSON.stringify(entry));
    }, 50);

    this.setState({ searchWord: entry });
  }

  render(): React.ReactNode {
    return <main className='dictionary'>
      <h1>Verben</h1>

      <div className='search-box'>
      <input type='text' value={ this.state.searchWord } onChange={ this.handleChange }></input>
      </div>

      <ul id='cards'>
        { this.sortedVerbs().map(v => <DictionaryEntry verb={v} key={v.present_infinitive}/>) }
      </ul>
    </main>
  }
}
