import {Verb} from '../verb'
import * as React from 'react'
import {useState} from 'react'
import * as L from 'lodash';

import "../array-extensions";

import * as QuizResultStore from "./verb-quiz/verb-quiz-store";
import {Wordlists} from "../word_list";

enum PickResultType { success = 'success', failure = 'failure' }

type PickSuccess = {
  type:    PickResultType.success,
  verb:    Verb,
  correct: string
}

type PickFailure = {
  type:   PickResultType.failure,
  verb:   Verb,

  given:   string,
  correct: string,
}

const makePickSuccess = (verb: Verb, correct: string): PickSuccess => ({
  type:    PickResultType.success,
  verb:    verb,
  correct: correct
});

const makePickFailure = (verb: Verb, correct: string, given: string): PickFailure => ({
  type:    PickResultType.failure,
  verb:    verb,
  correct: correct,
  given:   given
});

type PickResult = PickSuccess | PickFailure

interface ResultProps {
  pickResults: PickResult[],
}

function Results(props: ResultProps) {
  function success(s: PickSuccess) {
    return (
      <tr key={ `success-${ s.verb.identifier() }` } className={ 'success' }>
        <td className={ 'verb' } colSpan={ 2 }>{ s.verb.present_infinitive }</td>
        <td className={ 'correct' }>{ s.correct }</td>
      </tr>
    )
  }

  function failure(s: PickFailure) {
    return (
      <tr key={ `failure-${ s.verb.identifier() }` } className={ 'failure' }>
        <td className={ 'verb' }>{ s.verb.present_infinitive }</td>
        <td className={ 'given' }>{ s.given }</td>
        <td className={ 'correct' }>{ s.correct }</td>
      </tr>
    )
  }

  return <div className='results'>
    <table>
      <tbody>
      {
        props.pickResults.map((pick: PickResult) => {
          switch (pick.type) {
            case PickResultType.success:
              return success(pick)

            case PickResultType.failure:
              return failure(pick)
          }
        })
      }
      </tbody>
    </table>
  </div>
}

interface CardProps {
  verb:        Verb,
  picks:       string[],
  correctPick: string,
  onPick:      (string) => void,
  onPass:      ()       => void
}

interface CardState {
}

interface CardButtonProps {
  callback: () => void,
  label: string,
  isCorrect: boolean
}

function CardButton({ callback, label, isCorrect }: CardButtonProps) {
  const [pressed, setPressed] = useState(false);

  function cls(): string {
    const t: string[] = []

    if (isCorrect)
      t.push('correct')
    else
      t.push('incorrect')

    if (pressed) t.push('pressed')

    return t.join(' ')
  }

  function clicked() {
    setPressed(true)
  }

  function animationEnded() {
    setPressed(false)
    callback()
  }

  return (
    <>
      <button
        className      = { cls() }
        onClick        = { () => clicked() }
        onAnimationEnd = { () => animationEnded() }
      >{ label }
      </button>
    </>
  )
}

class Card extends React.Component<CardProps, CardState> {
  constructor(props: CardProps) {
    super(props);
    this.state = { }
  }

  render() {
    return <div className={ 'card' }>
      <ol>
        { this.props.picks.map(p =>
          <li key={ p }>
            <CardButton
              callback={ () => this.props.onPick(p) }
              label={ p }
              isCorrect={ this.props.correctPick == p }
            />
          </li>
        ) }
      </ol>
    </div>
  }
}

interface VerbQuizProps {
  verbs:   Verb[],
  onReset: () => void
}

interface VerbQuizState {
  currentCardNo: number,
  cardCount:     number,
  currentVerb:   Verb,
  correctPick:   string,
  allPicks:      string[],
  pickResults:   PickResult[]
}

interface QuizProgressProps {
  currentStep: number,
  steps:       number
}

function QuizProgress({ currentStep, steps }: QuizProgressProps) {
  function stepElems(): JSX.Element[] {
    const elems: JSX.Element[] = []

    for (let i = 0; i < steps; i++) {
      elems[i] = <li className={ 'incomplete' } key={ `step-${ i }` } />
    }

    for (let i = 0; i < currentStep; i++) {
      elems[i] = <li className={ 'complete' } key={ `step-${ i }` } />

      if (i == currentStep - 1) {
        elems[i] = <li className={ 'current' } key={ `step-${ i }` } />
      }
    }

    return elems;
  }

  return (
    <>
      <ul className={ 'quiz-progress' }>{ stepElems() }</ul>
    </>
  )
}

export class VerbQuiz extends React.Component<VerbQuizProps, VerbQuizState> {
  state: VerbQuizState = {
    currentVerb:   null,
    correctPick:   null,
    allPicks:      [],
    currentCardNo: 0, // HACK
    cardCount:     6,
    pickResults:  []
  }

  componentDidMount() {
    this.pickNextRandomCard()
  }

  componentDidUpdate(prevProps: Readonly<VerbQuizProps>, prevState: Readonly<VerbQuizState>, snapshot?: any) {
    if (prevProps.verbs.length === 0) {
      this.pickNextRandomCard()
    }
  }

  pickNextRandomCard() {
    const props = this.props;

    if (props.verbs.length == 0) {
      return
    }

    let next = props.verbs.returningRandomElement();

    let correctGerman: string = L.shuffle(next.german)[0];
    let allGerman: string[] = [];

    props.verbs.forEach(v => {
      v.german.forEach(g => {
        if (g != correctGerman) {
          allGerman.push(g);
        }
      })
    });

    let incorrectGerman = allGerman.returningRandomElements(3)
    let picks = [...incorrectGerman, ...[correctGerman]].returningShuffled()

    this.setState({
      currentVerb:   next,
      correctPick:   correctGerman,
      allPicks:      picks,
      currentCardNo: this.state.currentCardNo + 1
    })
  }

  picked(given: string) {
    const correct = this.state.correctPick

    let pickResult: PickResult

    if (correct == given) {
      pickResult = makePickSuccess(this.state.currentVerb, correct)
    } else {
      pickResult = makePickFailure(this.state.currentVerb, correct, given)
    }

    this.persistPick(pickResult)

    this.setState({ currentCardNo: this.state.currentCardNo + 1 })
    this.setState({ pickResults: this.state.pickResults.returningAppended(pickResult) })

    this.pickNextRandomCard()
  }

  persistPick(pickResult: PickResult) {
    switch(pickResult.type) {
      case PickResultType.success:
        QuizResultStore.persistQuizPick({
          quiz_type: 'inf_to_german',
          word_type: 'verb',
          word_id: pickResult.verb.identifier(),
          picked_at: (new Date()),
          was_correct: true,
          correct: pickResult.correct
        })
        .catch((e) => console.error(e))

        break

      case PickResultType.failure:
        QuizResultStore.persistQuizPick({
          quiz_type: 'inf_to_german',
          word_type: 'verb',
          word_id: pickResult.verb.identifier(),
          picked_at: (new Date()),
          was_correct: false,
          correct: pickResult.correct,
          given: pickResult.given
        })
        .catch((e) => console.error(e))
    }
  }

  render() {
    let cardElem: JSX.Element;

    if (this.state.currentVerb != null) {
      cardElem = <Card
        verb        = { this.state.currentVerb }
        picks       = { this.state.allPicks }
        correctPick = { this.state.correctPick }
        onPick      = { (v) => this.picked(v) }
        onPass      = { () => console.log(`skipped`)
      }/>
    } else {
      cardElem = <></>
    }

    const quiz = () => <>
      <h5>{ this.state.currentVerb && this.state.currentVerb.present_infinitive }</h5>

      <QuizProgress
        currentStep={ this.state.currentCardNo }
        steps={ this.state.cardCount } />

      <div className={ 'quiz' }>{ cardElem }</div>
    </>

    const results = () => <>
      <h2>Ergebnisse</h2>

      { Results({ pickResults: this.state.pickResults}) }

      <button onClick={ () => {
        // this.setState({
        //   currentVerb:   null,
        //   correctPick:   null,
        //   allPicks:      [],
        //   currentCardNo: 1, // HACK
        //   cardCount:     6,
        //   pickResults:  []
        // }, () => {
        //   this.pickNextRandomCard()
        // })

        this.props.onReset()
      }

      }>Neues Quiz</button>
    </>

    const r = () => {
      if (this.state.currentCardNo == this.state.cardCount + 1) {
        return results()
      } else {
        return quiz()
      }
    }

    return <main id='verb-quiz'>
      { r() }
    </main>
  }
}

interface VerbQuizSetupProps {
  verbs: Verb[]
}

export function VerbQuizSetup({ verbs }: VerbQuizSetupProps) {
  const [chosen,        updateChosen]         = useState<number[]>([])
  const [filteredVerbs, updatedFilteredVerbs] = useState<Verb[]>(null)

  function toggling<T>(xs: T[], x: T)  {
    let hasX = xs.indexOf(x) >= 0

    if (hasX) {
      return xs.returningFiltered(y => y != x)
    } else {
      return xs.returningAppended(x)
    }
  }

  let knownLectures: number[] = verbs
    .returningFiltered(v => v.lecture >= 1)
    .map(v => v.lecture)
    .returningUniq()
    .returningSortedWith((l: number, r: number) => l.toString().localeCompare(r.toString(), undefined, { numeric: true, sensitivity: 'base' }))

  function Lecture(num: number) {
    return <li key={ `lecture-${ num }` }>
      <label>
        <input type={"checkbox"} checked={ chosen.indexOf(num) >= 0 } onChange={ () => updateChosen(p => toggling(p, num)) }  />
        #{ num }
      </label>
    </li>
  }

  function go() {
    const chosenSet = new Set(chosen)
    const filtered   = verbs.returningFiltered(v => chosenSet.has(v.lecture))

    updatedFilteredVerbs(filtered)
  }

    if (filteredVerbs == null) {
      return <main className={ 'lecture-selector' }>
        <h1>Verb Quiz</h1>

        <h2>Kapitel</h2>

        <ol>{ knownLectures.map(l => Lecture(l)) }</ol>

        <ul className="button-group">
          <li>
            <button onClick={ () => updateChosen([]) }>Keine</button>
          </li>
          <li>
            <button onClick={ () => updateChosen(knownLectures) }>Alle</button>
          </li>
          <li className={ 'start' }>
            <button
              disabled={ chosen.length == 0 }
              onClick={ () => go() }>Start</button>
          </li>
        </ul>
      </main>
    } else {
      return <VerbQuiz verbs={ filteredVerbs } onReset={ () => {
        updatedFilteredVerbs(null)
      }
      }></VerbQuiz>
    }
}
