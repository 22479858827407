export { PersistedQuizPick, persistQuizPick, fetchAll }

type PersistedQuizPick = {
  quiz_type:   'inf_to_german' | 'unknown',
  word_type:   'verb',
  word_id:     string,
  picked_at:   Date,
  was_correct: boolean,
  correct:     string,
  given?:      string
}

type PersistedWithId = PersistedQuizPick & {
  'uuid': string
}

const DatabaseName = 'latin';
const QuizPickStoreName = 'quiz_picks';

async function persistQuizPick(pick: PersistedQuizPick): Promise<void> {
  let db = await openLatinDatabase(upgradeDb)
  let tx = db.transaction(QuizPickStoreName, 'readwrite')

  let quizPicks = tx.objectStore(QuizPickStoreName)
  let serialized = { ...pick }

  await awaitReq(quizPicks.put(serialized))
  await awaitTx(tx)
}

async function fetchAll(): Promise<PersistedQuizPick[]> {
  let db = await openLatinDatabase(upgradeDb)
  let tx = db.transaction(QuizPickStoreName, 'readonly')

  let quizPicks = tx.objectStore(QuizPickStoreName)

  return await awaitReq(quizPicks.getAll())
}

function openLatinDatabase(onUpgrade: (IDBDatabase) => void): Promise<IDBDatabase> {
  return new Promise((resolve, reject) => {
    let or = window.indexedDB.open(DatabaseName, 2)

    or.onsuccess       = () => resolve(or.result)
    or.onupgradeneeded = () => onUpgrade(or.result)
    or.onerror         = () => reject(or.error)
  })
}

function upgradeDb(db: IDBDatabase) {
  console.log(`updateDb for version: ${ db.version }`)

  if (db.objectStoreNames.contains(QuizPickStoreName)) {
    console.log(`deleting all object store`)
    db.deleteObjectStore(QuizPickStoreName)
  }

  db.createObjectStore(QuizPickStoreName, { keyPath: 'id', autoIncrement: true })
}

function awaitReq<T>(req: IDBRequest<T>): Promise<T> {
  return new Promise((resolve, reject) => {
    req.onsuccess = () => resolve(req.result)
    req.onerror  =  () => reject(req.error)
  })
}

function awaitTx(tx: IDBTransaction): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    tx.oncomplete = () => resolve()
    tx.onerror    = () => reject(tx.error)
  })
}
