import { Verb }   from '../verb';
import * as React from 'react';
import * as L     from 'lodash';

// Flash cards with spaced repetition work by thinking about the answer, guessing, and then 'flipping' the card.
// The choices in Anki (which are sensible IMO) are 'Incorrect', 'Easy', and 'Hard'

// https://faqs.ankiweb.net/what-spaced-repetition-algorithm.html
// https://www.supermemo.com/en/archives1990-2015/english/ol/sm2

enum EncounterSelection {
  Missed = 'missed',
  Easy   = 'easy',
  Hard   = 'hard'
}

interface Encounter {
  date:      Date,
  selection: EncounterSelection
}

interface FlashcardStats {
  verbId:      string,
  first_seen?: Date,
  last_seen?:  Date,
  encounters:  Encounter[]
}

let encounter: Encounter = {
  date: new Date(Date.now()),
  selection: EncounterSelection.Missed
}

console.log(JSON.stringify(encounter));

export { Flashcard as FlashCards };

interface FlashcardProps {
  verbs: Verb[]
}

enum FlashcardSetting {
  GermanToInfinite   = 'GermanToInfininive',
  InfinitiveToGerman = 'InfinitiveToGerman'
}

interface FlashcardState {
  setting:      FlashcardSetting;
  currentVerb?: Verb;
}

class Flashcard extends React.Component<FlashcardProps, FlashcardState> {
  constructor(props: FlashcardProps) {
    super(props)

    this.state = {
      setting: FlashcardSetting.GermanToInfinite
    };
  }

  logV(v:Verb) {
    console.log(`click on ${ v.identifier() }`)
  }

  render(): React.ReactNode {
    return <main>
      <h1>Flashcards</h1>

      <ul>
        <li>
          Verbs are { this.props.verbs.map(v => <span key={ v.identifier() } onMouseEnter={ () => this.logV(v) }>{ v.present_infinitive } </span>) }
        </li>
      </ul>
    </main>
  }
}
