import * as React from 'react';

import {render} from 'react-dom';
import {HashRouter, NavLink, Route, Routes} from 'react-router-dom';

import { Dictionary } from './include/components/dictionary';
import { VerbTest }   from './include/components/verb-test';
import { FlashCards } from './include/components/flashcards';
import { VerbQuizSetup }   from './include/components/verb-quiz';

import {Verb} from './include/verb';
import {AppContext} from "./include/app_context";

interface RootState {
  verbs: Verb[],
  renderMacrons: boolean,
  toggleMacrons: () => void
}

async function fetchVerbs(): Promise<Verb[]> {
  let promise =
    fetch('https://latin-cards.fsvehla.at/data/verbs.json')
      .then(r => r.json())
      .then(r => r as Verb[])

  let verbs: Verb[] = await promise

  return verbs.map(v => {
    return Object.assign(new Verb(), v);
  })
}

class Root extends React.Component<{}, RootState> {
  constructor(props) {
    super(props)

    let initialRenderMacrons = window.localStorage.getItem('render-macrons') !== 'false';

    this.state = {
      verbs: [],
      renderMacrons: initialRenderMacrons,
      toggleMacrons: () => {
        if (this.state.renderMacrons) {
          window.localStorage.setItem('render-macrons', 'false');

          this.setState({ renderMacrons: false });
        } else {
          window.localStorage.setItem('render-macrons', 'true');

          this.setState({ renderMacrons: true });
        }
      }
    };
  }

  componentDidMount(): void {
    fetchVerbs().then(v => this.setState({ "verbs": v }));
  }

  render(): React.ReactNode {
    return (
      <AppContext.Provider value={ { renderMacrons: this.state.renderMacrons, toggleMacrons: this.state.toggleMacrons } }>
        <HashRouter>
          <header>
            <nav>
              <NavLink to="">Wörterbuch</NavLink>
              <NavLink className={ 'desktop-only' } to="verb-test">Verb Test</NavLink>
              { /*<NavLink to="flashcards">Flashcards</NavLink>*/ }
              <NavLink to="verb-quiz">Verben: Quiz</NavLink>
            </nav>

            {/*<div className='options'>*/}
            {/*  <label>*/}
            {/*    <input type='checkbox' checked={ this.state.renderMacrons } onChange={ this.state.toggleMacrons } />*/}
            {/*    Länge der Vokale anzeigen (ā, ē, …)*/}
            {/*  </label>*/}
            {/*</div>*/}
          </header>

          <Routes>
            <Route index element={<Dictionary verbs={ this.state.verbs } />} />
            <Route path="verb-test" element={<VerbTest />} />
            <Route path="flashcards" element={<FlashCards verbs={ this.state.verbs } />} />
            <Route path="verb-quiz" element={ <VerbQuizSetup verbs={ this.state.verbs } /> } />
          </Routes>
        </HashRouter>
      </AppContext.Provider>
    )
  }
}

render(<Root />, document.getElementById('root'));
