export enum ConjugationType {
  A = 'a',
  E = 'e',
}

export class Verb {
  present_infinitive: string;
  lecture:            number;

  present_p1_s:       string;
  present_p2_s:       string;
  present_p3_s:       string;

  present_p1_p:       string;
  present_p2_p:       string;
  present_p3_p:       string;

  perfect_p1_s:       string;
  perfect_p2_s:       string;
  perfect_p3_s:       string;

  perfect_p1_p:       string;
  perfect_p2_p:       string;
  perfect_p3_p:       string;

  ppp:                string;
  german:             string[];

  matchesAny(str: string): boolean {
    let opts: string[] = [
      this.present_infinitive,
      this.present_p1_s,
      this.present_p2_s,
      this.present_p3_s,
      this.present_p1_p,
      this.present_p2_p,
      this.present_p3_p,
      this.perfect_p1_s,
      this.perfect_p2_s,
      this.perfect_p3_s,
      this.perfect_p1_p,
      this.perfect_p2_p,
      this.perfect_p3_p,
      this.ppp
    ]

    this.german.forEach(g => opts.push(g))

    let normalized = opts.map(o => removeMacrons(o))
    let needle     = removeMacrons(str)

    let f = false

    normalized.forEach(o => {
      if (o.indexOf(needle) > -1) {
        f = true;
      }
    })

    return f
  }

  pppNeuter(): string {
    return this.ppp.replace('us/-a/-um', 'um').replace('us, -a, -um', 'um')
  }

  identifier(): string {
    return `verb_${ removeMacrons(this.present_infinitive) }`
  }

  conjugationType(): ConjugationType {
    if (removeMacrons(this.present_infinitive).endsWith('are')) {
      return ConjugationType.A
    }

    if (this.lecture <= 6) {
      if (removeMacrons(this.present_infinitive).endsWith('ere')) {
        return ConjugationType.E
      }

      // Beyond lecture 6 Medias in Res introduces multiple conjugations
    }

    return null;
  }

  presentStem(): string {
    if (this.conjugationType() == ConjugationType.A) {
      return this.present_infinitive.normalize('NFC').replace(/āre/gu, 'a')
    }

    if (this.conjugationType() == ConjugationType.E) {
      return this.present_infinitive.replace('ere', '').replace('ēre', '')
    }

    return null;
  }
}

export function removeMacrons(str: string): string {
  return str.normalize("NFD").replace(/\p{Diacritic}/gu, '')
}
